import React from 'react'
import * as productCodes from '@/constants/product-codes'
import { SvgSprite } from '@saatva-bits/pattern-library.components.svg-sprite'

const accordionItems = {
    gotContent: 'accordion-content-gots',
    gotTitle: 'accordion-title-gots',
    ecoContent: 'accordion-content-ecoinstitut',
    ecoTitle: 'accordion-title-ecoinstitut',
    greenContent: 'accordion-content-greenguard',
    greenTitle: 'accordion-title-greenguard',
    golsContent: 'accordion-content-gols',
    golsTitle: 'accordion-title-gols'
}

const organicLatex = (hasCoils) => {
    return {
        heading: 'Pure comfort starts with pure&nbsp;materials',
        customMediaClassName: 'u-bgColor--accentGreenLight',
        tabs: [
            {
                tabName: 'Organic',
                heading: 'Healthier for the planet',
                hasScrollLink: true,
                dynamicContent (RedirectComponent) {
                    return (
                        <>
                            <p>We use <RedirectComponent copy='GOLS certified' accordionContent={accordionItems.golsContent} /> organic natural latex, our cover is made with highly breathable organic cotton, and our premium New Zealand wool is <RedirectComponent copy='GOTS® certified' accordionContent={accordionItems.gotContent} /> organic. </p>
                            <p>NO harmful pesticides, GMOs, toxic chemicals, heavy metals, fertilizers, and dyes. NO fiberglass or fiberglass sock flame retardants.</p>
                        </>
                    )
                },
                icons: ['icon-certification-gots', 'icon-certification-gols', 'icon-certification-eco-institut', 'icon-certification-greenguard']
            },
            {
                tabName: 'Natural',
                heading: 'Healthier sleep by nature',
                content: <p>We only use the highest quality sustainably-sourced materials nature has to offer: natural latex, cotton, {hasCoils ? 'wool, and recycled steel coils' : 'and wool'}. NO petroleum-based foams or fibers.</p>,
                icons: ['icon-certification-gots', 'icon-certification-gols', 'icon-certification-eco-institut', 'icon-certification-greenguard']
            },
            {
                tabName: 'Nontoxic',
                heading: 'Healthier indoor air',
                hasScrollLink: true,
                dynamicContent (RedirectComponent) {
                    return (
                        <p>Breathe easy. Our natural latex mattress is <RedirectComponent copy='eco-INSTITUT®' accordionContent={accordionItems.ecoContent} /> & <RedirectComponent copy='GREENGUARD®' accordionContent={accordionItems.greenContent} /> Gold certified to have low VOCs (volatile organic compounds) and total chemical emissions. Plus, we never use chemical flame retardants.</p>
                    )
                },
                icons: ['icon-certification-gots', 'icon-certification-gols', 'icon-certification-eco-institut', 'icon-certification-greenguard']
            }
        ]
    }
}

const comfortLevelZenhaven = () => {
    return {
        heading: 'Dual-sided for your perfect comfort level',
        customMediaClassName: 'u-bgColor--accentGreenLight',
        tabs: [
            {
                tabName: 'Luxury Plush',
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-plush' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText plushText'>4-5</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Soft yet incredibly weightless and buoyant</li>
                            <li>Body-contouring to relieve pressure points</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Side sleepers, but also great for back and combination sleepers</li>
                            <li>Couples who can’t agree on firmness</li>
                        </ul>
                    </>
                )
            },
            {
                tabName: 'Gentle Firm',
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-firm' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText firmText'>7-8</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>18% firmer than the Luxury Plush side, with more “push back” support and responsiveness</li>
                            <li>A true firm, but never hard on the back and joints</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Stomach sleepers or those who need/prefer a firm mattress</li>
                            <li>Sleepers who love more uplifting support</li>
                        </ul>
                    </>
                )
            }
        ]
    }
}

const comfortLevelLoomAndLeaf = () => {
    return {
        heading: 'Choose your comfort level',
        hasAlternateColor: true,
        customMediaClassName: 'u-bgColor--accentBlue',
        tabs: [
            {
                tabName: 'Relaxed Firm',
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-luxury-blue' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText luxuryFirmText'>5-7</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Hugs the body without allowing the hips to sink too far</li>
                            <li>A true medium firmness, cushioned but not too soft</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Side sleepers, but also great for back and combination sleepers</li>
                            <li>Couples who can’t agree on firmness</li>
                        </ul>
                    </>
                )
            },
            {
                tabName: 'Firm',
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-firm-blue' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText firmTextBlue'>8</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Has more “push back” support and responsiveness than the Relaxed Firm</li>
                            <li>A true firm, but never hard on the back and joints</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Stomach sleepers or those who need/prefer a firm mattress</li>
                            <li>Sleepers who love memory foam but move around a lot</li>
                        </ul>
                    </>
                )
            }
        ]
    }
}
const comfortLevelContour5 = () => {
    return {
        heading: 'Choose your comfort level',
        hasAlternateColor: true,
        customMediaClassName: 'u-bgColor--accentBlue',
        tabs: [
            {
                tabName: 'Medium',
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-luxury-blue' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText luxuryFirmText'>5-7</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Hugs the body without allowing the hips to sink too far</li>
                            <li>A true medium firmness, cushioned but not too soft</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Side sleepers, but also great for back and combination sleepers</li>
                            <li>Couples who can’t agree on firmness</li>
                        </ul>
                    </>
                )
            },
            {
                tabName: 'Firm',
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-firm-blue' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText firmTextBlue'>8</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Has more “push back” support and responsiveness than the Medium</li>
                            <li>A true firm, but never hard on the back and joints</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Back and stomach sleepers or those who need/prefer a firm mattress</li>
                            <li>Heavier weight sleepers over 230 pounds</li>
                            <li>Sleepers who love memory foam but move around a lot</li>
                        </ul>
                    </>
                )
            }
        ]
    }
}
const comfortLevelClassic = () => {
    return {
        heading: 'Choose your comfort level',
        hasAlternateColor: true,
        customMediaClassName: 'u-bgColor--accentBlue',
        tabs: [
            {
                tabName: 'Plush Soft',
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-soft-blue' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText plushTextBlue'>3</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Soft & cushiony thanks to the extra padding on top</li>
                            <li>Body-hugging to relieve pressure points</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Side sleepers who need extra pressure relief on the hips and shoulders</li>
                            <li>Lighter-weight people who often find mattresses to be too firm</li>
                        </ul>
                    </>
                )
            },
            {
                tabName: 'Luxury Firm',
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-luxury-blue' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText luxuryFirmText'>5-7</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Medium firmness, perfect balance of cushioning and uplifting support</li>
                            <li>Designed to replicate the feel of luxury hotel beds</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>All sleep positions</li>
                            <li>Couples who can’t agree on firmness</li>
                        </ul>
                    </>
                )
            },
            {
                tabName: 'Firm',
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-firm-blue' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText firmTextBlue'>8</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Lying on top of the mattress rather than sinking in</li>
                            <li>Balanced resistance that’s never hard on the back and joints</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelList'>
                            <li>Stomach & back sleepers or heavier body types</li>
                            <li>Sleepers who need an extra-firm mattress</li>
                            <li>Ideal for heavier weight sleepers 250-300 pounds</li>
                        </ul>
                    </>
                )
            }
        ]
    }
}
const comfortLevelSaatvaHD = () => {
    return {
        heading: 'Comfort for every body',
        hasAlternateColor: true,
        customMediaClassName: 'u-bgColor--accentBlue',
        tabs: [
            {
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-luxury-blue' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText luxuryFirmText'>5-7</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelListAlternative'>
                            <li>Cushioned feel of a pillow top paired with the buoyancy of natural latex and body-hugging feel of memory foam</li>
                            <li>A balanced medium feel for pressure-free comfort</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelListAlternative'>
                            <li>All sleep positions</li>
                            <li>Couples who can't agree on firmness</li>
                        </ul>
                        <br />
                        <p className="t-italic t-bodySm u-displayBlock"><small>*For sleepers over 300 pounds. Sleepers under 300 pounds might find the Saatva HD to have a firmer feel</small></p>
                    </>
                )
            }
        ]
    }
}
const comfortLevelMemoryFoamHybrid = () => {
    return {
        heading: 'The perfect medium firmness',
        hasAlternateColor: true,
        customMediaClassName: 'u-bgColor--accentBlue',
        tabs: [
            {
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-luxury-blue' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText luxuryFirmText'>5-7</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelListAlternative'>
                            <li>Cushioned but not too soft</li>
                            <li>Body-contouring yet responsive</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelListAlternative'>
                            <li>Side sleepers, but also great for back and combination sleepers</li>
                            <li>Couples who can't agree on firmness</li>
                        </ul>
                    </>
                )
            }
        ]
    }
}
const comfortLevelSaatvaLatexHybrid = () => {
    return {
        heading: 'The perfect medium firmness',
        hasAlternateColor: true,
        customMediaClassName: 'u-bgColor--accentBlue',
        tabs: [
            {
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-medium-firm' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText luxuryFirmText'>6</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelListAlternative'>
                            <li>Responsive support with a buoyant feel</li>
                            <li>Body-contouring to relieve pressure points</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelListAlternative'>
                            <li>All sleep positions, best suited for back and stomach sleepers</li>
                            <li>Couples who can't agree on firmness</li>
                        </ul>
                    </>
                )
            }
        ]
    }
}
const comfortLevelSaatvaRx = () => {
    return {
        heading: 'Plush comfort without sacrificing support',
        hasAlternateColor: true,
        customMediaClassName: 'u-bgColor--accentBlue',
        tabs: [
            {
                content: (
                    <>
                        <div className="tabbedContentDisplay__comfortMeter">
                            <SvgSprite className="tabbedContentDisplay__comfortMeterIcon" spriteID='icon-comfort-supporitve-plush' />
                            <div className="tabbedContentDisplay__comfortMeterIcons">
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-soft' />
                                <p className='tabbedContentDisplay__comfortMeterText plushText'>3-4</p>
                                <SvgSprite className='tabbedContentDisplay__comfortMeterSvg' spriteID='icon-luxury' />
                            </div>
                            <div className="tabbedContentDisplay__comfortMeterLabels">
                                <p>Soft</p>
                                <p>Firm</p>
                            </div>
                        </div>
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>How it feels:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelListAlternative'>
                            <li>Instantly relieves pressure on back and joints</li>
                            <li>Body-contouring and responsive</li>
                        </ul>
                        <br />
                        <h3 className='tabbedContentDisplay__comfortLevelHeading'>Ideal for:</h3>
                        <ul className='tabbedContentDisplay__comfortLevelListAlternative'>
                            <li>Side sleepers, but also great for back and combination sleepers</li>
                            <li>Stomach sleepers under 130 pounds</li>
                            <li>Sleepers with serious, chronic back & joint conditions</li>
                        </ul>
                    </>
                )
            }
        ]
    }
}

const solairePersonalizedComfort = () => {
    const BASE_LINK = '/bases/adjustable-base-plus?sku='
    return {
        heading: 'Get even more personalized comfort options with our Saatva Adjustable Base Plus',
        hasAlternateColor: true,
        tabContentWrapperClassName: 'u-marginTop--lg',
        tabs: [
            {
                tabName: 'Upper-Flex',
                heading: `Solaire Upper-Flex + Adjustable${'\u00A0'}Base Plus`,
                content: (
                    <>
                        <ul>
                            <li>Gives each sleeper the freedom to raise the head of the mattress independently</li>
                            <li>Great for alleviating mild snoring, relaxing with a good book, or watching TV at the perfect angle</li>
                        </ul>
                        <a className='tabbedContentDisplay__link' href={`${BASE_LINK}8105-5/0`}>Add a Saatva Adjustable Base Plus, save $100</a>
                    </>
                )
            },
            {
                tabName: 'Split King/Cal King',
                heading: `Solaire Split King/Cal King + Adjustable${'\u00A0'}Base Plus Split King/Cal King`,
                content: (
                    <>
                        <ul>
                            <li>Gives each sleeper the freedom to raise the head and the feet of the mattress independently</li>
                            <li>Your most customizable sleep experience ever</li>
                        </ul>
                        <a className='tabbedContentDisplay__link' href={`${BASE_LINK}8109-3XL-split-bundle`}>Add a Saatva Adjustable Base Plus Split King/Cal King, save $100</a>
                    </>
                )
            }
        ]
    }
}

const config = {
    [`${productCodes.SAATVA_LATEX_HYBRID}`]: {
        ...organicLatex(true)
    },
    [`${productCodes.ZENHAVEN}`]: {
        ...organicLatex(false)
    },
    [`${productCodes.SOLAIRE}--personalized-comfort`]: {
        ...solairePersonalizedComfort()
    },
    [`${productCodes.ZENHAVEN}--comfort-level`]: {
        ...comfortLevelZenhaven(false)
    },
    [`${productCodes.SAATVA_CLASSIC}--comfort-level`]: {
        ...comfortLevelClassic(false)
    },
    [`${productCodes.LOOM_AND_LEAF}--comfort-level`]: {
        ...comfortLevelLoomAndLeaf(false)
    },
    [`${productCodes.SAATVA_CONTOUR5}--comfort-level`]: {
        ...comfortLevelContour5(false)
    },
    [`${productCodes.CLASSIC_STARTER_BUNDLE}--comfort-level`]: {
        ...comfortLevelClassic(false)
    },
    [`${productCodes.SAATVA_HD}--comfort-level`]: {
        ...comfortLevelSaatvaHD(false)
    },
    [`${productCodes.MEMORY_FOAM_HYBRID}--comfort-level`]: {
        ...comfortLevelMemoryFoamHybrid(false)
    },
    [`${productCodes.SAATVA_LATEX_HYBRID}--comfort-level`]: {
        ...comfortLevelSaatvaLatexHybrid(false)
    },
    [`${productCodes.SAATVA_RX}--comfort-level`]: {
        ...comfortLevelSaatvaRx(false)
    },
}

export default config
